import * as React from "react";
import Map from "./Map";

import { getIsVertical } from "../utils/getIsVertical";

import videsPieejamiba from "../files/Vides pieejamība centrā RŪRE.pdf";
import registracijasIzzina from "../files/Magnum Social&Medical care, SIA_e-izziņa.pdf";
import infoLV from "../files/Info pacientiem_klientiem.pdf";
import infoRU from "../files/Info pacientiem_klientiem_ru.pdf";
import dienasKartiba from "../files/Dienas kārtība.pdf";
import kartibasNoteikumi from "../files/SOCIĀLĀ PAKALPOJUMA SNIEGŠANAS IEKŠĒJĀS KĀRTĪBAS NOTEIKUMI.pdf";

import "./BottomCommonSection.css";

function BottomCommonSection() {
    const isVertical = getIsVertical();

    return (
        <div className="contentBlock noPadding contactInfo">
            <div className="mapContainer">
                <Map />
            </div>
            <div className="contactInfoRight">
                <h2 className="contactInfoRightTitle">
                    <a href="mailto:info@rure.lv">Info@rure.lv</a><br />
                    <a href="tel:+37129396285">+371 293 962 85</a>
                </h2>
                <div className="contactInfoRightInfo">
                    <div>Darba dienās<br />9:00—17:00</div>
                    <div>Labraga iela 11,<br />Liepāja, LV—3414</div>
                </div>
                <div className="downloads">
                    <a href={kartibasNoteikumi} download><h3>Rūre centra iekšējie kārtības noteikumi</h3></a>
                    <a href={videsPieejamiba} download><h3 style={{ width: "auto" }}>Vides pieejamība centrā Rūre</h3></a>
                    <a href={registracijasIzzina} download><h3 style={{ width: "auto" }}>Izziņa par reģistrāciju ārstniecības iestāžu reģistrā</h3></a>
                    <a href={infoLV} download><h3 style={{ width: "auto" }}>Informācija pacientiem/klientiem</h3></a>
                    <a href={infoRU} download><h3 style={{ width: "auto" }}>Informācija pacientiem/klientiem (RU)</h3></a>
                    <a href={dienasKartiba} download><h3 style={{ width: "auto" }}>Dienas kārtība</h3></a>
                </div>
            </div>
        </div>
    );
}

export default BottomCommonSection;