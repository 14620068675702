import React from "react";

export default function Map() {
	return (
		<iframe
			width="100%"
			height="100%"
			style={{ border: 0 }}
			allowFullScreen={false}
			loading="lazy"
			referrerPolicy="no-referrer-when-downgrade"
			src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB61ml2u25DUzvNphOedxCkvrHZcuCkl-s&q=56.543078,21.072010&zoom=17&center=56.543075,21.071986"
		/>
	);
}
