import * as React from "react";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import BottomCommonSection from "../components/BottomCommonSection";
import { getIsVertical } from "../utils/getIsVertical";

import "../styles/paliativas-aprupes-klinika.css";

export default function PalliativeCareClinic() {
	const isVertical = getIsVertical();

	return (
		<Layout pageId={PageId.PalliativeCareClinic}>
			<Seo
				title="Paliatīvās aprūpes klīnika"
				description="Veselības centrā Rūre tiek nodrošināta arī paliatīvā un hospisa aprūpe, kas  kas koncentrējas uz nedziedināmi slimo pacientu aprūpi dzīves beigās. Tās prioritāte ir vairot dzīves kvalitāti un komfortu, mazinot sāpes un ciešanas."
			/>
			<div className="contentBlock topTextSection">
				<h1>Veselības centrā Rūre tiek nodrošināta arī paliatīvā un hospisa aprūpe, kas koncentrējas uz nedziedināmi slimo pacientu aprūpi dzīves beigās. Tās prioritāte ir vairot dzīves kvalitāti un komfortu, mazinot sāpes un ciešanas.</h1>
			</div>
			<div className="contentBlock" style={isVertical ? { padding: "8rem 0" } : {}}>
				<div className="palliativeDescription">
					<p>Kas ir paliatīvā{isVertical ? "" : <br />} aprūpe?</p>
					<div className="palliativeDescriptionTextBlock first">
						<p>Paliatīvā aprūpe ir specializēta medicīniskā aprūpe cilvēkiem, kuriem diagnosticēta slimība terminālā stadijā. Šāda veida aprūpe ir vērsta uz slimības simptomu un stresa mazināšanu. Mērķis ir uzlabot dzīves kvalitāti gan pacientam, gan ģimenei.</p>
						<p>Paliatīvo aprūpi nodrošina īpaši apmācīta ārstu, medmāsu un citu speciālistu komanda. Paliatīvā aprūpe balstās uz pacienta vajadzībām, nevis uz pacienta prognozi.</p>
					</div>
				</div>
				<div className="palliativeDescription">
					<p>Kas ir hospisa{isVertical ? "" : <br />} aprūpe?</p>
					<div className="palliativeDescriptionTextBlock">
						<p>Hospisa aprūpe ir veselības aprūpes veids, kas koncentrējas uz nedziedināmi slimo pacientu aprūpi dzīves beigās. Tās prioritāte ir vairot dzīves kvalitāti un komfortu, mazinot sāpes un ciešanas.</p>
					</div>
				</div>
			</div>
			<div className="contentBlock whatsOffered">
				<p>Ko piedāvā Rūre paliatīvās aprūpes klīnika?</p>
				<div className="offers">
					<div>Ārsta izstrādāts un personalizēts medicīniskās aprūpes plāns katram pacientam</div>
					<div>Pacienta veselības stāvokļa kontrole medicīnas personāla uzraudzībā visas diennakts garumā</div>
					<div>Manipulācijas (medikamentu ievade, brūču apkopšana un pārsiešana,{isVertical ? "" : <br />} mākslīgo atveru kopšana t.sk. traheostomas kopšana)</div>
					<div>Fizioterapija un fizikālā terapija</div>
					<div>Psihologa un kapelāna pakalpojumi</div>
					<div>Lai saņemtu pakalpojumu veselības centrā Rūre nepieciešams slimnīcas konsīlija slēdziens par indicētu paliatīvu aprūpi, simptomātisku terapiju.</div>
					<div>Pēc konsīlija slēdziena saņemšanas, pacients un/vai pacienta piederīgie var sazināties ar Rūre koordinatoru un pieteikt pirmreizējo vizīti.</div>
				</div>
				<p className="pricesTitle">Pakalpojuma cenas</p>
				<div className="palliativePrices">
					<div>
						<div className="palliativePrice">
							<h2>47<span>€</span></h2>
							<h2>/ Dienā</h2>
						</div>
						<p>Trīsvietīga istaba</p>
					</div>
					<div>
						<div className="palliativePrice">
							<h2>55<span>€</span></h2>
							<h2>/ Dienā</h2>
						</div>
						<p>Divvietīga istaba</p>
					</div>
					<div>
						<div className="palliativePrice">
							<h2>71,5<span>€</span></h2>
							<h2>/ Dienā</h2>
						</div>
						<p>Vienvietīga istaba</p>
					</div>
				</div>
			</div>
			<BottomCommonSection />
		</Layout>
	);
}
